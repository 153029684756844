<template>
  <div>
    <h2>Iberisches Schweinefleisch ist etwas Besonderes</h2>
    <div class="side-by-side">
      <img src="@/assets/images/porkMeat.jpg" style="margin-right: 1em" />
      <p>
        Im Gegensatz zum „weißen Schwein“, wie es hier genannt wird, hat das iberische Schweinefleisch eine dunkelrote
        Farbe und kann von weitem mit rotem Rinderfleisch verwechselt werden. Neben der Genetik liegt der Hauptgrund
        darin, dass das iberische Schwein den ganzen Tag aktiv ist. Es gibt keinen Stall mit wenig Platz zum Bewegen.
        Stattdessen laufen die Tiere die ganze Zeit herum. Ein Schwein kann schneller rennen als ein Mensch. Auch die
        Ernährung des Schweins macht einen großen Unterschied.
      </p>
    </div>
  </div>
</template>

<style scoped>
.side-by-side {
  display: grid;
  grid-template-columns: auto auto;
}

@media (max-width:440px) {
  .side-by-side {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
