<template>
  <div>
    <h2>Montanera - Acorn Diet</h2>
    <picture>
      <source media="(max-width: 500px)" srcset="../../assets/images/sm-montanera.jpg">
      <img src="../../assets/images/montanera.jpg">
    </picture>
    <p>
      In den Monaten November bis Ende Februar liefern unsere Eichen (Quercus ilex sp. bellota) eine Menge
      köstliche und süße Eicheln. Während dieser Zeit füttern wir den Schweinen kein zusätzliches Futter. Die
      Ausnahme ist, wenn wir einen Futtersack verwenden, um sie ein wenig anzulocken, damit sie z.B. in eine Koppel
      für die Impfung gehen.
    </p>
    <p>
      Die Eicheln und das, was sie sonst noch finden können, sind mehr als genug, um sie fett werden zu lassen.
    </p>
  </div>
</template>
