<template>
  <div>
    <h1>Schweine</h1>
    <p>
      Derzeit arbeiten wir mit iberischen Schweinen. Die Tiere leben im Freien. Es gibt keine Scheune oder einen
      anderen Unterschlupf, aber wir stellen ihnen Material zur Verfügung, mit dem sie ihren eigenen bauen können.
    </p>
    <picture>
      <source media="(max-width: 500px)" srcset="../../assets/images/sm-pigs.jpg">
      <img src="../../assets/images/pigs.jpg">
    </picture>

    <p>
      Die Schweine machen sich gerne ihr "Bett" und schlafen dann als ein großer Haufen um sich nachts gegenseitig
      warm zu halten. Die Tiere sind an unser Klima angepasst. Die Temperaturen betragen bis zu 45°C an einem heißen
      Sommertag und bis zu minus 15°C an einem kalten Wintertag.
    </p>
    <picture>
      <source media="(max-width: 500px)" srcset="../../assets/images/sm-pigsStraw.jpg">
      <img src="../../assets/images/pigsStraw.jpg">
    </picture>
  </div>
</template>
