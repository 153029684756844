<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <PigIntroductionDE />
      <PigBenefitsForSoilDEVue />
      <PigMontaneraDE />
      <PigPorkDE />

      <div v-if="hasListAnimals">
        <h2>Unsere Iberico-Schweine</h2>
        <p>
          Aktuell haben wir {{ animalCount }} Schweine auf unserem Land.

          <span v-if="hasPorkMeatSales">Wenn Sie daran interessiert sind, Fleisch für Ihre Familie zu kaufen, verwenden
            Sie
            einfach den Kaufen-Knopf wo er erscheint.</span>
        </p>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Alter</th>
              <th>Geschlecht</th>
              <th v-if="hasPorkMeatSales">Aktion</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="animal in animals" :key="animal.externalId">
              <td>{{ animal.externalId }}</td>
              <td>{{ animal.ageInMonths }} Monate</td>
              <td>{{ animal.sex }}</td>
              <td v-if="hasPorkMeatSales"><button v-if="animal.readyForSale" class="animal-buy"
                  @click.stop="buyForMeat(animal.animalId)">Buy</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';
import { setupFeatureFlags } from '@/library'
import { axiosInstance } from '@/library'
import PigIntroductionDE from './PigIntroductionDE.vue'
import PigBenefitsForSoilDEVue from './PigBenefitsForSoilDE.vue'
import PigMontaneraDE from './PigMontaneraDE.vue'
import PigPorkDE from './PigPorkDE.vue'

export default {
  name: "PigsDE",
  components: {
    Sidebar,
    PigIntroductionDE,
    PigBenefitsForSoilDEVue,
    PigMontaneraDE,
    PigPorkDE
  },

  data: () => ({
    animals: []
  }),

  computed: {
    animalCount () {
      return this.animals.length
    }
  },

  setup () {
    return setupFeatureFlags()
  },

  beforeMount () {
    this.getInventory()
  },

  methods: {
    getInventory () {
      axiosInstance.get(`${window.VUE_APP_GRANJA}/api/animals/PIG/IBERICO`)
        .then((response) => {
          this.animals = response.data.animals
        })
    },

    buyForMeat (animalId) {
      this.$router.push({ name: 'ibericoMeat', params: { animalId } })
    },

  }
};
</script>

<style scoped>
TABLE {
  border-collapse: separate;
  border-spacing: 30px 0;
}
</style>
