<template>
  <div>
    <h2>Ökologischer Nutzen für den Boden</h2>
    <p>
      Genauso wie Wildschweine graben unsere Schweine den Boden auf der Suche nach Wurzeln und Bodenlebewesen
      um. Sie sind der Pflug der Natur und helfen dem Wasser beim Versickern. Sie fressen auch Lebewesen, die
      für Pflanzen schädlich sein könnten, wenn es zu viele gibt.
    </p>
    <p>
      Außerdem helfen uns Schweine neue Samen in den Boden zu integrieren, damit Pflanzen wachsen können. Sie zerstören
      zuerst ein bisschen, aber wenn sie früh genug auf eine andere Fläche verlegt werden, ist der Nutzen größer als der
      Schaden jemals sein könnte.
    </p>
  </div>
</template>
